<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <div class="row my-2 align-items-center">
      <div class="col-md-6">
        <h4>Sales Over View</h4>
      </div>
      <div class="col-md-3">
        <DatePicker
          format="yyyy-MM-dd"
          type="date"
          name="from_date"
          v-model="search.from_date"
          id="from_date"
          placeholder="Select From Date"
          :clearable="false"
          style="width: 100%"
        ></DatePicker>
      </div>
      <div class="col-md-3">
        <DatePicker
          format="yyyy-MM-dd"
          type="date"
          name="to_date"
          v-model="search.to_date"
          id="to_date"
          placeholder="Select To Date"
          :clearable="false"
          style="width: 100%"
        ></DatePicker>
      </div>
    </div>
    <SalesOverView :search="search" class="mb-2" />
    <CourseWiseSalesOverView :search="search" class="mb-2" />
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BCardText,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import SalesOverView from "./partials/SalesOverView.vue";
import CourseWiseSalesOverView from "./partials/CourseWiseSalesOverView.vue";

export default {
  data() {
    return {
      search: {
        from_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        to_date: new Date(),
        search_data: "",
        paginete: 10,
        page: 1,
      },
    };
  },
  components: {
    SalesOverView,
    CourseWiseSalesOverView,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
